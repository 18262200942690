import classNames from "classnames";
import * as Icons from "react-icons/fi";

import styles from "./index.module.scss";

interface ISvgIconProps {
  type: keyof typeof Icons;
  className?: string;
  onClick?: () => void;
}

function SvgIcon({ type, className, onClick }: ISvgIconProps) {
  // eslint-disable-next-line import/namespace
  const CustomSvg = Icons[type];

  return (
    <CustomSvg
      className={classNames(styles.icon, className)}
      onClick={onClick}
    />
  );
}

SvgIcon.defaultProps = {
  className: "",
  onClick: () => {},
};

export default SvgIcon;
