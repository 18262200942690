import { Theme, useMediaQuery } from "@mui/material";
import classNames from "classnames";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import useCurrentRoutes from "../../../hooks/useCurrentRoutes";
import { destroySession } from "../../../store/actions/sessionActions";
import { showToast } from "../../../store/slices/toastSlice";
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
import { ROOT } from "../../../utils/constants/routes";
import TOAST from "../../../utils/constants/toast";
import CustomNavLink from "../../shared/CustomNavLink";
import SvgButton from "../../shared/SvgButton";
import Logo from "../Logo";

import styles from "./index.module.scss";

// TypeScript interface defining the props structure expected by the Header component.
interface IHeaderProps {
  isMenuVisible: boolean;
  onMenuToggle: () => void;
}

// Header component definition, accepting props defined in IHeaderProps.
function Header({ isMenuVisible, onMenuToggle }: IHeaderProps) {
  // Hooks for dispatching actions to Redux and navigating programmatically.
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  // Custom hook to get the current routes configuration, likely for dynamic navigation rendering.
  const { sideMenu } = useCurrentRoutes();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg"),
  );

  // Handler for click events on navigation items, with special handling for logout.
  const handleLinkClick = (routeKey: string) => () => {
    switch (routeKey) {
      case "LOGOUT": {
        dispatch(destroySession()); // Dispatches an action to clear the session.

        dispatch(
          showToast({
            type: TOAST.SUCCESS_TYPE,
            message: "Signed out",
            duration: TOAST.DEFAULT_DURATION,
          }),
        );

        navigate(ROOT); // Navigates to the application's root.
        break;
      }

      default: {
        // No default action.
        break;
      }
    }
  };

  // Accessing the session state from the Redux store.
  const session = useTypedSelector((state) => state.session);

  // Component's rendered output.
  return (
    <header className={styles.container}>
      {/* Renders the Logo component, with an additional prop for text visibility. */}
      <Logo pathname={ROOT} />

      {/* Navigation section, dynamically rendered based on the current route configuration. */}
      <nav className={styles.nav}>
        {Object.entries(sideMenu).map(([routeKey, route]) => {
          const { type, label } = route;

          const finalLabel = label();

          // Conditionally renders buttons or links based on the route type.
          if (finalLabel && type === "button") {
            return (
              <span
                key={routeKey}
                className={styles.buttonLink}
                onClick={handleLinkClick(routeKey)}>
                {label()}
              </span>
            );
          }

          if (finalLabel && type === "link") {
            return (
              <CustomNavLink
                key={routeKey}
                to={routeKey}
                label={label()}
                className={styles.link}
              />
            );
          }

          // Fallback for other types, rendering nothing.
          return <Fragment key={routeKey} />;
        })}
      </nav>

      {isMobile && (
        <>
          <SvgButton
            icon="FiMenu"
            onClick={onMenuToggle}
            className={classNames(styles.menuIcon, {
              [styles.menuIcon_visible]: !isMenuVisible && session.agency,
            })}
          />

          <SvgButton
            icon="FiX"
            onClick={onMenuToggle}
            className={classNames(styles.menuIcon, {
              [styles.menuIcon_visible]: isMenuVisible,
            })}
          />
        </>
      )}
    </header>
  );
}

// Exporting the Header component for use in other parts of the application.
export default Header;
