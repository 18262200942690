import { useTypedSelector } from "../../../../../store/store";
import parseTextWithLinks from "../../../../../utils/helpers/parseTextWithLinks";
import Checkbox from "../../../../shared/Checkbox";

import styles from "./index.module.scss";

// Defining the interface for the component's props.
interface ILegalDataProps {
  checkbox_1_checked: boolean;
  checkbox_2_checked: boolean;
  checkbox_3_checked: boolean;
  checkbox_4_checked: boolean;
  checkbox_5_checked: boolean;
  checkbox_6_checked: boolean;
  onChange: ({ value, valueKey }: { value: boolean; valueKey: string }) => void;
}

// LegalData component for displaying legal checkboxes and handling changes.
function LegalData({
  checkbox_1_checked,
  checkbox_2_checked,
  checkbox_3_checked,
  checkbox_4_checked,
  checkbox_5_checked,
  checkbox_6_checked,
  onChange,
}: ILegalDataProps) {
  // Accessing environment state from Redux store.
  const {
    privacy_policy,
    payment_checkbox_1,
    payment_checkbox_2,
    payment_checkbox_3,
    payment_checkbox_4,
    payment_checkbox_5,
    payment_checkbox_6,
  } = useTypedSelector((state) => state.environment);

  // Function to handle checkbox value changes.
  const handleChange = ({
    value,
    valueKey,
  }: {
    value: boolean;
    valueKey: string;
  }) => onChange({ value, valueKey });

  // Rendering the legal data with parsed text for markdown links.
  return (
    <div className={styles.container}>
      {privacy_policy && (
        <p>
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: parseTextWithLinks(privacy_policy, styles.links),
              }}
            />
          </>
        </p>
      )}

      {payment_checkbox_1 && (
        <Checkbox
          value={checkbox_1_checked}
          valueKey="checkbox_1"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(payment_checkbox_1, styles.links),
                }}
              />
            </>
          }
        />
      )}

      {payment_checkbox_2 && (
        <Checkbox
          value={checkbox_2_checked}
          valueKey="checkbox_2"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(payment_checkbox_2, styles.links),
                }}
              />
            </>
          }
        />
      )}

      {payment_checkbox_3 && (
        <Checkbox
          value={checkbox_3_checked}
          valueKey="checkbox_3"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(payment_checkbox_3, styles.links),
                }}
              />
            </>
          }
        />
      )}

      {payment_checkbox_4 && (
        <Checkbox
          value={checkbox_4_checked}
          valueKey="checkbox_4"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(payment_checkbox_4, styles.links),
                }}
              />
            </>
          }
        />
      )}

      {payment_checkbox_5 && (
        <Checkbox
          value={checkbox_5_checked}
          valueKey="checkbox_5"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(payment_checkbox_5, styles.links),
                }}
              />
            </>
          }
        />
      )}

      {payment_checkbox_6 && (
        <Checkbox
          value={checkbox_6_checked}
          valueKey="checkbox_6"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(payment_checkbox_6, styles.links),
                }}
              />
            </>
          }
        />
      )}
    </div>
  );
}

// Exporting the component for use in other parts of the application.
export default LegalData;
