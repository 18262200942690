import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

function NickoTermsOfLiability() {
  const { t } = useTranslation();

  return (
    <section className="legal-section">
      <h2 className={styles.title}>{t("Liability and Cancellation Policy")}</h2>

      <h3>{t("Liability Disclaimer")}</h3>

      <p>
        {t(
          "nicko cruises does not assume any liability for services booked separately by the tour operator or travel agency, such as visas, flights, hotels, etc. The agent is obliged to communicate the corresponding limitations of liability to its contractual partners and to agree them accordingly. In the event of a violation, the partner will be liable to NC for compensation.",
        )}
      </p>

      <p className={styles.paragraph}>
        {t(
          "Nicko cruises is only liable for the services it provides (cruise services) and only in accordance with the applicable provisions of German travel law (§§ 651 ff. of the German Civil Code). The agent must be fully aware of what is included in the General Travel Conditions of NC, and customers must be informed accordingly.",
        )}
      </p>

      <h3>{t("Cancellation by nicko cruises")}</h3>

      <p>
        {t(
          "nicko cruises is entitled to cancel a trip free of charge due to force majeure. A case of force majeure exists if, due to unforeseeable events that are not the responsibility of the parties—such as war, blockades, civil war or civil war-like conditions, piracy, hostage-taking, revolution, pandemics, natural disasters like high or low water, or due to damage to the ship or its engines—the trip becomes impossible and must be canceled. In such cases, the commission claim of the agent is fully canceled.",
        )}
      </p>

      <p>
        {t(
          "nicko cruises is also authorized to cancel trips free of charge if the minimum number of passengers as advertised and agreed upon is not reached. In such cases, the agent’s commission claim shall become null and void.",
        )}
      </p>
    </section>
  );
}

export default NickoTermsOfLiability;
