import { preparePaymentPath } from "./helpers";

export const ENV_VARS = {
  api_url: process.env.REACT_APP_DEV_API_URL as string,
  pos_url: process.env.REACT_APP_DEV_POS_API_URL as string,
  search_url: process.env.REACT_APP_DEV_SEARCH_API_URL as string,
  payment_url: process.env.REACT_APP_DEV_CABIN_SELECT_PAYMENT_DOMAIN as string,
  api_username: process.env.REACT_APP_DEV_API_USERNAME as string,
  api_password: process.env.REACT_APP_DEV_API_password as string,
  payment_api_path: preparePaymentPath(
    process.env.REACT_APP_PAYMENT_API_PATH as string,
    process.env.REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_MINE as string,
  ),
  date_format: process.env.REACT_APP_CABIN_SELECT_DATE_FORMAT as string,
  app_language: process.env.REACT_APP_CABIN_SELECT_APP_LANGUAGE as string,
  api_language: process.env.REACT_APP_CABIN_SELECT_API_LANGUAGE as string,
  breadcrumbs_text: process.env.REACT_APP_BREADCRUMBS_TEXT as string,
  search_item_image_source: process.env.REACT_APP_SEARCH_ITEM_IMAGE_SOURCE as
    | "ship"
    | "cruise",
  authorize_api_login_id: process.env
    .REACT_APP_CABIN_SELECT_AUTHORIZE_API_LOGIN_ID as string,
  authorize_client_key: process.env
    .REACT_APP_CABIN_SELECT_AUTHORIZE_CLIENT_KEY as string,
  privacy_policy: process.env.REACT_APP_PRIVACY_POLICY as string,
  payment_checkbox_1: process.env.REACT_APP_PAYMENT_CHECKBOX_1 as string,
  payment_checkbox_2: process.env.REACT_APP_PAYMENT_CHECKBOX_2 as string,
  payment_checkbox_3: process.env.REACT_APP_PAYMENT_CHECKBOX_3 as string,
  payment_checkbox_4: process.env.REACT_APP_PAYMENT_CHECKBOX_4 as string,
  payment_checkbox_5: process.env.REACT_APP_PAYMENT_CHECKBOX_5 as string,
  payment_checkbox_6: process.env.REACT_APP_PAYMENT_CHECKBOX_6 as string,
  subtract_gft: process.env.REACT_APP_SUBTRACT_GFT === "true",
  strip_no_stop_itinerary_items:
    process.env.REACT_APP_STRIP_NO_STOP_ITINERARY_ITEMS === "true",
  show_pricing_breakdown:
    process.env.REACT_APP_SHOW_PRICING_BREAKDOWN === "true",
  check_email_uniqueness:
    process.env.REACT_APP_CHECK_EMAIL_UNIQUENESS === "true",
  authorize_is_test:
    process.env.REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_TEST === "true",
  authorize_is_mine:
    process.env.REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_MINE === "true",
  primary_color: process.env.REACT_APP_PRIMARY_COLOR as string,
  secondary_color: process.env.REACT_APP_SECONDARY_COLOR as string,
  text_primary_color: process.env.REACT_APP_TEXT_PRIMARY_COLOR as string,
  text_secondary_color: process.env.REACT_APP_TEXT_SECONDARY_COLOR as string,
  subtext_color: process.env.REACT_APP_SUBTEXT_COLOR as string,
  link_color: process.env.REACT_APP_LINK_COLOR as string,
  input_border_color: process.env.REACT_APP_INPUT_BORDER_COLOR as string,
  input_background_color: process.env
    .REACT_APP_INPUT_BACKGROUND_COLOR as string,
  input_placeholder_color: process.env
    .REACT_APP_INPUT_PLACEHOLDER_COLOR as string,
  input_focus_border_color: process.env
    .REACT_APP_INPUT_FOCUS_BORDER_COLOR as string,
  input_text_color: process.env.REACT_APP_INPUT_TEXT_COLOR as string,
  error_color: process.env.REACT_APP_ERROR_COLOR as string,
  menu_background_color: process.env.REACT_APP_MENU_BACKGROUND_COLOR as string,
  menu_text_color: process.env.REACT_APP_MENU_TEXT_COLOR as string,
  element_background_primary: process.env
    .REACT_APP_ELEMENT_BACKGROUND_PRIMARY as string,
  element_background_secondary: process.env
    .REACT_APP_ELEMENT_BACKGROUND_SECONDARY as string,
  element_background_dark: process.env
    .REACT_APP_ELEMENT_BACKGROUND_DARK as string,
  background_color: process.env.REACT_APP_BACKGROUND_COLOR as string,
  section_background_color: process.env
    .REACT_APP_SECTION_BACKGROUND_COLOR as string,
  shadow_color: process.env.REACT_APP_SHADOW_COLOR as string,
  delimiter_line_color: process.env.REACT_APP_DELIMITTER_LINE_COLOR as string,
  svg_button_color: process.env.REACT_APP_SVG_BUTTON_COLOR as string,
  svg_icons_primary: process.env.REACT_APP_SVG_ICONS_PRIMARY as string,
  svg_icons_secondary: process.env.REACT_APP_SVG_ICONS_SECONDARY as string,
  pagination_type: process.env.REACT_APP_PAGINATION_TYPE as string,
  pagination_count: isNaN(Number(process.env.REACT_APP_PAGINATION_COUNT))
    ? 10
    : Number(process.env.REACT_APP_PAGINATION_COUNT),
  search_button_background: process.env
    .REACT_APP_SEARCH_BUTTON_BACKGROUND as string,
  skip_payment_step: process.env.REACT_APP_SKIP_PAYMENT_STEP === "true",
  agency_commission: Number(process.env.REACT_APP_AGENCY_COMMISSION ?? "0"),
  api_accounts: process.env.REACT_APP_API_ACCOUNTS ?? "",
  agency_name: process.env.REACT_APP_AGENCY_NAME ?? "",
  logo_url: process.env.REACT_APP_LOGO_URL ?? "",
  welcome_title: process.env.REACT_APP_WELCOME_TEXT_TITLE ?? "",
  favicon: process.env.REACT_APP_FAVICON ?? "",
  auth_page_background: process.env.REACT_APP_AUTH_PAGE_BACKGROUND ?? "",
  forgotten_password_page_background:
    process.env.REACT_APP_FORGOTTEN_PASSWORD_PAGE_BACKGROUND ?? "",
  start_booking_section_background:
    process.env.REACT_APP_START_BOOKING_SECTION_BACKGROUND ?? "",
  current_bookings_section_background:
    process.env.REACT_APP_CURRENT_BOOKINGS_SECTION_BACKGROUND ?? "",
  previous_bookings_section_background:
    process.env.REACT_APP_PREVIOUS_BOOKINGS_SECTION_BACKGROUND ?? "",
  hero_section_background: process.env.REACT_APP_HERO_SECTION_BACKGROUND ?? "",
  agency_account_type:
    process.env.REACT_APP_AGENCY_ACCOUNT_TYPE ?? "creditcardonly",
  start_booking_title: process.env.REACT_APP_START_BOOKING_TITLE ?? "",
  start_booking_description:
    process.env.REACT_APP_START_BOOKING_DESCRIPTION ?? "",
  current_bookings_title: process.env.REACT_APP_CURRENT_BOOKINGS_TITLE ?? "",
  current_bookings_description:
    process.env.REACT_APP_CURRENT_BOOKINGS_DESCRIPTION ?? "",
  previous_bookings_title: process.env.REACT_APP_PREVIOUS_BOOKINGS_TITLE ?? "",
  previous_bookings_description:
    process.env.REACT_APP_PREVIOUS_BOOKINGS_DESCRIPTION ?? "",
  start_page_title: process.env.REACT_APP_START_PAGE_TITLE ?? "",
  current_page_title: process.env.REACT_APP_CURRENT_PAGE_TITLE ?? "",
  previous_page_title: process.env.REACT_APP_PREVIOUS_PAGE_TITLE ?? "",
  yacht_name_placeholder: process.env.REACT_APP_YACHT_NAME_PLACEHOLDER ?? "",
  number_stateroom_page_description:
    process.env.REACT_APP_NUMBER_STATEROOM_PAGE_DESCRIPTION ?? "",
  gft_additional_text: process.env.REACT_APP_GFT_ADDITIONAL_TEXT ?? "",
  pnr_column_name: process.env.REACT_APP_BOOKING_PNR_COLUMN_NAME ?? "",
  cruise_column_name: process.env.REACT_APP_CRUISE_COLUMN_NAME ?? "",
  agent_home_tiles: process.env.REACT_APP_AGENT_HOME_TILES ?? "[]",
  destination_input_placeholder:
    process.env.REACT_APP_DESTINATION_INPUT_PLACEHOLDER ?? "",
  bin_restriction: process.env.REACT_APP_BIN_RESTRICTION ?? "",
  full_deck_plan_link: process.env.REACT_APP_FULL_DECK_PLAN_LINK ?? "",
  deck_legend: process.env.REACT_APP_DECK_LEGEND ?? "",
  show_cancellation_policy:
    process.env.REACT_APP_SHOW_CANCELLATION_POLICY === "true",
  passenger_continue_text: process.env.REACT_APP_PASSENGER_CONTINUE_TEXT ?? "",
  payment_option_text: process.env.REACT_APP_PAYMENT_OPTION_TEXT ?? "",
  is_manual_payment_allowed:
    process.env.REACT_APP_IS_MANUAL_PAYMENT_ALLOWED === "true",
  registration_link_text: process.env.REACT_APP_REGISTRATION_LINK_TEXT ?? "",
  current_booking_payment_info:
    process.env.REACT_APP_CURRENT_BOOKING_PAYMENT_INFO ?? "",
  payment_instruction_info:
    process.env.REACT_APP_PAYMENT_INSTRUCTION_INFO ?? "",
  new_booking_payment_info:
    process.env.REACT_APP_NEW_BOOKING_PAYMENT_INFO ?? "",
  header_background_color: process.env.REACT_APP_HEADER_BACKGROUND_COLOR ?? "",
  header_text_color: process.env.REACT_APP_HEADER_TEXT_COLOR ?? "",
  footer_background_color: process.env.REACT_APP_FOOTER_BACKGROUND_COLOR ?? "",
  footer_text_color: process.env.REACT_APP_FOOTER_TEXT_COLOR ?? "",
  background_fade_color: process.env.REACT_APP_BACKGROUND_FADE_COLOR ?? "",
  meta_title: process.env.REACT_APP_META_TITLE ?? "",
  meta_description: process.env.REACT_APP_META_DESCRIPTION ?? "",
  rooms_sleep_guests_number:
    process.env.REACT_APP_ROOMS_SLEEP_GUESTS_NUMBER ?? "3",
  airwallex_env: process.env.REACT_APP_AIRWALLEX_ENV ?? "",
};
