import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";

import SvgButton from "../SvgButton";

import styles from "./index.module.scss";

interface IInputProps {
  className?: string;
  value: string;
  valueKey?: string;
  label?: string;
  placeholder?: string;
  autoComplete?: string;
  name?: string;
  type?: string;
  errorMessage?: string;
  isRequired?: boolean;
  readOnly?: boolean;
  secured?: boolean;
  showError?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  onChange?: ({ value, valueKey }: { value: string; valueKey: string }) => void;
  onBlur?: ({ value, valueKey }: { value: string; valueKey: string }) => void;
}

function Input({
  className,
  value,
  valueKey,
  label,
  placeholder,
  autoComplete,
  name,
  type,
  errorMessage,
  isRequired,
  readOnly,
  secured,
  showError,
  onClick,
  onChange,
  onBlur,
  disabled,
}: IInputProps) {
  const [inputType, setInputType] = useState(secured ? "password" : type);

  const ref = useRef<HTMLDivElement>(null);

  const id = useMemo(nanoid, []);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange({ value: e.target.value, valueKey: valueKey ?? "" });
    }
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur({ value: e.target.value, valueKey: valueKey ?? "" });
    }
  };

  const handleIconClick = () => {
    const nextInputType = inputType === "password" ? type : "password";

    setInputType(nextInputType);
  };

  useEffect(() => {
    if (showError && errorMessage) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [errorMessage, showError]);

  return (
    <div ref={ref} className={classNames(styles.container, className)}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
          {isRequired && <span className={styles.asterisk}>*</span>}
        </label>
      )}

      <div className={styles.fieldContainer}>
        <input
          id={id}
          type={inputType}
          className={classNames(styles.field, {
            [styles.field_error]: !!errorMessage,
            [styles.field_with_icon]: secured,
          })}
          value={value}
          placeholder={placeholder}
          name={name}
          readOnly={readOnly}
          autoComplete={autoComplete}
          onClick={handleClick}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
        />

        {secured && (
          <SvgButton
            className={styles.icon}
            icon={inputType === "password" ? "FiEyeOff" : "FiEye"}
            onClick={handleIconClick}
          />
        )}
      </div>

      {showError && <span className={styles.error}>{errorMessage}</span>}
    </div>
  );
}

Input.defaultProps = {
  className: "",
  valueKey: "",
  label: "",
  placeholder: "",
  autoComplete: "",
  name: "",
  type: "text",
  errorMessage: "",
  isRequired: false,
  readOnly: false,
  secured: false,
  showError: true,
  handleClick: () => {},
  onChange: () => {},
};

export default Input;
