import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  getPriceByCruise,
  selectSearch,
  selectSearchResultsByFilter,
} from "../../../store/selectors";
import { SearchAPI } from "../../../store/services/SearchService";
import { ICruise } from "../../../store/slices/searchSlice";
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
import { countryCodeToFullName } from "../../../utils/helpers/country";
import { currencyToFormat } from "../../../utils/helpers/currency";
import SearchFilter from "../../base/SearchFilter";
import LoadingContainer from "../../containers/LoadingContainer";
import CustomLink from "../../shared/CustomLink";
import PaginationComponent from "../../shared/Pagination";

import styles from "./index.module.scss";

// Declaring SearchResults functional component
function SearchResults() {
  const dispatch = useTypedDispatch(); // Initializing useDispatch hook for typed dispatch
  const { t } = useTranslation(); // Initializing useTranslation hook for translation

  const { pathname, search } = useLocation(); // Getting current pathname and search query from location

  const { results, isLoading } = useTypedSelector(selectSearchResultsByFilter); // Getting search results and loading state from Redux store
  const { results: searchResults } = useTypedSelector(selectSearch); // Getting search results from Redux store

  // Getting search item image source from Redux store
  const {
    search_item_image_source,
    date_format,
    api_language: language,
  } = useTypedSelector((state) => state.environment);

  // Function to initialize search results
  const initSearchResults = () => {
    // Dispatching action to initialize search results
    dispatch(
      SearchAPI.endpoints.initSearchResults.initiate({ search, language }),
    );
  };

  // Function to get price from cruise object
  const getPriceFrom = (cruise: ICruise) => {
    // Getting price by cruise from Redux store
    const price = getPriceByCruise(cruise);

    return currencyToFormat(price, cruise?.markets?.currency ?? "USD");
  };

  // Running initSearchResults function on component mount
  useEffect(initSearchResults, []);

  const [items, setItems] = useState<ICruise[]>([]);

  // Returning JSX
  return (
    // Container for SearchResults component
    <div className={styles.container}>
      {/* Title for search results */}
      <h1 className={styles.title}>{t("search results")}</h1>

      {/* Loading container */}
      <LoadingContainer isLoading={isLoading}>
        {/* Rendering search filter if there are multiple results */}
        {searchResults && searchResults?.length > 1 && <SearchFilter />}
        {/* Container for search results */}
        <div className={styles.results}>
          {/* Checking if there are no results */}
          {!items?.length ? (
            <p>{t("no results found")}</p>
          ) : (
            // Mapping through search results
            items.map((cruise) => {
              const priceFrom = getPriceFrom(cruise); // Getting price from cruise

              // Returning JSX for each search result
              return (
                // Container for each search result item
                <div key={cruise.code} className={styles.item}>
                  {/* Image for search result */}
                  <img
                    alt={cruise?.cruise?.name}
                    src={cruise[search_item_image_source]?.images?.[0]}
                    className={styles.image}
                  />

                  {/* Bottom section of search result item */}
                  <div className={styles.bottom}>
                    {/* Body section of search result item */}
                    <div className={styles.body}>
                      {/* Name of cruise */}
                      <p className={styles.name}>{cruise?.cruise?.name}</p>

                      {/* Details table */}
                      <table className={styles.details}>
                        <tbody>
                          {/* Row for ship name */}
                          <tr>
                            <td>{t("SHIP NAME")}</td>
                            <td>{cruise?.ship?.name}</td>
                          </tr>

                          {/* Row for region */}
                          <tr>
                            <td>{t("REGION")}</td>
                            <td>
                              {cruise?.cruise?.countries
                                ?.map(countryCodeToFullName)
                                .join(", ")}
                            </td>
                          </tr>

                          {/* Row for departure date */}
                          <tr>
                            <td>{t("DEPARTS")}</td>
                            <td>{dayjs(cruise?.embark).format(date_format)}</td>
                          </tr>

                          <tr>
                            <td>{t("NIGHTS")}</td>
                            <td>{cruise?.cruise.nights}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {/* Footer section of search result item */}
                    <div className={styles.footer}>
                      {/* Price section */}
                      <div className={styles.price}>
                        <span className={styles.price_from}>{t("From")}</span>

                        <span className={styles.price_value}>
                          {/* Price value with price for person */}
                          {priceFrom}&nbsp;pp
                        </span>
                      </div>

                      {/* Button to navigate to cruise details */}
                      <CustomLink
                        className={styles.button}
                        to={`${pathname}/${cruise.code}${search}&source=${cruise.source}`}>
                        {t("next")}
                      </CustomLink>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>

        <PaginationComponent data={results} setData={setItems} />
      </LoadingContainer>
    </div>
  );
}

// Exporting SearchResults component
export default SearchResults;
